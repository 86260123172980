@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Julee&family=League+Spartan&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@charset "UTF-8";
header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 1rem;
  z-index: 9999999999 !important;
  background-color: rgba(255, 255, 255, 0.788);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  border-radius: 0px 0px 50px 50px;
  box-shadow: 0px 0px 10px 0px rgba(56, 56, 56, 0.5);
  width: 100%;
}
@media only screen and (max-width: 1440px) {
  header {
    padding: 20px 3rem;
    border-radius: 0px 0px 40px 40px;
  }
}
@media only screen and (max-width: 720px) {
  header {
    padding: 20px 1rem;
    border-radius: 0px 0px 40px 40px;
  }
}
header .logo {
  position: absolute;
  top: 0;
  left: 50%;
  height: 50px;
  width: auto;
  object-fit: cover;
  -webkit-transform: translate(-50%, 40%) scale(2);
          transform: translate(-50%, 40%) scale(2);
}
@media screen and (max-width: 720px) {
  header .logo {
    -webkit-transform: translate(-50%, 40%) scale(1.65);
            transform: translate(-50%, 40%) scale(1.65);
  }
}
header .hamburger {
  font-size: 50px;
  -webkit-transform: translateY(20%) scale(0.75);
          transform: translateY(20%) scale(0.75);
}
@media screen and (max-width: 720px) {
  header .hamburger {
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
  }
}
header .nav {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  grid-gap: 20px;
  gap: 20px;
  height: -webkit-min-content;
  height: min-content;
}
header .nav__button {
  position: relative;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
          -ms-user-select: none;
      user-select: none;
  align-content: center;
}
header .nav__button__img {
  height: 32px;
  width: 32px;
  object-fit: cover;
}
header .nav__button__count {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(50%, -25%);
          transform: translate(50%, -25%);
  border-radius: 50%;
  background-color: #d61616;
  color: #fff;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
header .nav__option {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
          -ms-user-select: none;
      user-select: none;
  font-weight: 500;
  height: 15px;
  font-size: 15px !important;
  letter-spacing: 1px;
  color: #000;
  z-index: 9999999;
  font-weight: 800;
  transition: 0.5s ease-in-out;
}
header .nav__option--dark {
  background: #66C3D1;
  color: #fff;
  padding: 10px 10px;
  line-height: 2px;
  border-radius: 15px;
}
header .nav__option--dark:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  color: #fff;
  text-decoration: none !important;
}
header .nav__option .arrow__down {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(45deg) translate(0, -70%);
          transform: rotate(45deg) translate(0, -70%);
}
header .nav__option .nav__dropdown {
  display: none;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  margin: 10px 0;
  grid-gap: 10px;
  gap: 10px;
  padding: 30px 50px;
  font-weight: 300;
  color: #222;
  text-wrap: nowrap;
  width: 300px;
  background-color: white;
  z-index: 9999999;
}
header .nav__option .nav__dropdown:hover {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
header .nav__option:hover {
  text-decoration: underline;
}
header .nav__option:hover .nav__dropdown {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
header .nav__option:hover .nav__dropdown > a:hover {
  text-decoration: underline;
}

.nav__menu {
  position: relative;
  transition: 0.5s ease-in-out;
}
.nav__menu__logo {
  height: 32px;
  width: 32px;
  object-fit: cover;
  margin-right: 10px;
}
.nav__menu__content {
  position: fixed;
  top: 0;
  left: 0;
  width: auto;
  min-width: 300px;
  height: 100vh;
  background-color: white;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  font-weight: lighter;
  -webkit-animation: init__menu__content 0.5s ease-in-out forwards;
          animation: init__menu__content 0.5s ease-in-out forwards;
}
@media screen and (max-width: 720px) {
  .nav__menu__content {
    width: 80%;
  }
}
.nav__menu__button {
  height: 32px;
  width: 32px;
  object-fit: cover;
}
.nav__menu__button img {
  height: 32px;
  width: 32px;
  object-fit: cover;
}
.nav__menu__button--cart img {
  height: 32px;
  width: 38px;
  object-fit: cover;
}
.nav__menu__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  -webkit-animation: init__menu__overlay 0.5s ease-in-out forwards;
          animation: init__menu__overlay 0.5s ease-in-out forwards;
}
.nav__menu__title {
  position: relative;
  margin: 5px 20px;
  font-size: 24px;
  font-weight: 500;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
}
.nav__menu__title::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 75%;
  height: 2px;
  background-color: #000;
}
.nav__menu__icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
  margin-right: 5px;
}
.nav__menu__choice {
  color: #333;
  font-size: 14px;
}
.nav__menu__choice__title {
  position: relative;
  font-size: 24px;
  font-weight: 500;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  color: black;
}
.nav__menu__choice__content {
  -webkit-animation: nav__menu__choice__content 0.5s ease-in-out forwards;
          animation: nav__menu__choice__content 0.5s ease-in-out forwards;
}
.nav__menu__choice__icon--open {
  -webkit-animation: nav__menu__choice__icon__open 0.5s ease-in-out forwards;
          animation: nav__menu__choice__icon__open 0.5s ease-in-out forwards;
}
.nav__menu__choice__icon--close {
  -webkit-animation: nav__menu__choice__icon__close 0.5s ease-in-out forwards;
          animation: nav__menu__choice__icon__close 0.5s ease-in-out forwards;
}
.nav__menu__close {
  cursor: pointer;
  -webkit-user-select: none;
          -ms-user-select: none;
      user-select: none;
  transition: 0.5s ease-in-out;
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 5%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
.nav__menu__close:hover {
  -webkit-transform: translate(-50%, 0) rotate(360deg);
          transform: translate(-50%, 0) rotate(360deg);
}

@-webkit-keyframes init__menu__content {
  from {
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
  }
  to {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@keyframes init__menu__content {
  from {
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
  }
  to {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@-webkit-keyframes init__menu__overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes init__menu__overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes nav__menu__choice__title__open {
  from {
    width: 0;
  }
  to {
    width: 75%;
  }
}
@keyframes nav__menu__choice__title__open {
  from {
    width: 0;
  }
  to {
    width: 75%;
  }
}
@-webkit-keyframes nav__menu__choice__title__close {
  from {
    width: 75%;
  }
  to {
    width: 0;
  }
}
@keyframes nav__menu__choice__title__close {
  from {
    width: 75%;
  }
  to {
    width: 0;
  }
}
@-webkit-keyframes nav__menu__choice__content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes nav__menu__choice__content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes nav__menu__choice__icon__open {
  from {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes nav__menu__choice__icon__open {
  from {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@-webkit-keyframes nav__menu__choice__icon__close {
  from {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes nav__menu__choice__icon__close {
  from {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
.slider {
  height: 100% !important;
}
.slider div {
  border: 0;
}
@media screen and (max-width: 720px) {
  .slider {
    height: auto;
    width: 100%;
  }
}
.slider .slide {
  z-index: 0;
}
.slider .slick-arrow {
  padding: auto;
  margin: auto;
  z-index: 1;
  background-color: #000 !important;
  color: white !important;
  width: 50px;
  height: 50px;
}
.slider .slick-prev {
  position: absolute;
  bottom: 0;
  left: none;
  right: 0;
  border-radius: 50%;
}
.slider .slick-prev:before {
  font-family: "Quicksand", sans-serif !important;
  content: "←";
  border-radius: 100%;
}
.slider .slick-next {
  position: absolute;
  top: 50%;
  right: 0;
  border-radius: 50%;
}
.slider .slick-next:before {
  font-family: "Poppins", sans-serif !important;
  content: "→";
  border-radius: 100%;
}
.slider .slick-dots {
  bottom: 15px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}
.slider .slick-dots li {
  margin: 0 10px !important;
}
.slider .slick-dots li button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  cursor: pointer;
}
.slider .slick-dots li button:before {
  content: none;
}
.slider .slick-dots .slick-active {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
}

#price__filter {
  background-color: #bbb;
  height: 2px;
}

#price__filter .range-slider__range {
  background: #000;
  transition: height 0.3s;
}

#price__filter .range-slider__thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #333;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

#price__filter .range-slider__thumb[data-active] {
  -webkit-transform: translate(-50%, -50%) scale(1.25);
          transform: translate(-50%, -50%) scale(1.25);
}

#price__filter .range-slider__range[data-active] {
  height: 16px;
}

.cart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.cart__product {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .cart__product {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.cart__product__preview {
  position: relative;
}
.cart__product__image {
  width: 80px;
  height: 100px;
  object-fit: contain;
}
.cart__product__climage {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.cart__product__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 12px;
  padding: 0 5px;
}

.checkout {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.checkout__item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid black;
  padding: 20px;
  font-size: 12px;
}

.configurator {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}
@media screen and (max-width: 720px) {
  .configurator {
    flex-direction: column;
  }
}
.configurator .container {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  margin: 0;
}
.configurator .choices {
  width: 50%;
}
@media screen and (max-width: 720px) {
  .configurator .choices {
    width: 100%;
  }
}
.configurator .preview {
  position: relative;
  width: 512px;
  height: 512px;
}
@media screen and (max-width: 720px) {
  .configurator .preview {
    width: 100%;
    height: 100%;
  }
}
.configurator .preview .disclaimer {
  font-family: "Architects Daughter", sans-serif !important;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 100%);
          transform: translate(-50%, 100%);
  font-size: 12px;
  text-align: center;
}
@media screen and (max-width: 720px) {
  .configurator .preview .disclaimer {
    font-size: 8px;
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
  }
}
.configurator .preview .image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.configurator .preview .border {
  position: absolute;
  top: 25%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 150px;
  height: 150px;
  object-fit: contain;
  border: 2px rgba(100, 100, 100, 0.5) dashed !important;
}
.configurator .preview .border--pillow {
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border: 2px rgba(100, 100, 100, 0.5) dashed !important;
}
@media screen and (max-width: 720px) {
  .configurator .preview .border--pillow {
    width: 200px;
    height: 200px;
  }
}
.configurator .preview .border--pack {
  top: 67.5%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 2px rgba(100, 100, 100, 0.5) dashed !important;
}
.configurator .preview .border--pack1 {
  top: 35%;
  left: 30%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 2px rgba(100, 100, 100, 0.5) dashed !important;
}
.configurator .preview .border--pack2 {
  top: 35%;
  left: 74%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 2px rgba(100, 100, 100, 0.5) dashed !important;
}
.configurator .preview .border--hoodie-back {
  top: 60%;
}
.configurator .preview .client__img {
  position: absolute;
  top: 25%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 150px;
  height: 150px;
  object-fit: contain;
}
.configurator .preview .client__img--pillow {
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
}
@media screen and (max-width: 720px) {
  .configurator .preview .client__img--pillow {
    width: 200px;
    height: 200px;
  }
}
.configurator .preview .client__img--pack {
  top: 67.5%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}
.configurator .preview .client__img--pack1 {
  top: 35%;
  left: 30%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}
.configurator .preview .client__img--pack2 {
  top: 35%;
  left: 74%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}
.configurator .preview .client__img--hoodie-back {
  top: 30%;
}
.configurator .add__image {
  width: 256px;
  height: 256px;
  object-fit: contain;
  cursor: pointer;
}
.configurator .size {
  color: black !important;
  background-color: whitesmoke;
}
.configurator .white {
  background-color: #fff !important;
}
.configurator .black {
  background-color: #000 !important;
}
.configurator .darkgray {
  background-color: #323638 !important;
}
.configurator .marineblue {
  background-color: #192a35 !important;
}
.configurator .lightgray {
  background-color: lightgray !important;
}
.configurator .gray {
  background-color: #7a757b !important;
}
.configurator .lightcream {
  background-color: #c6bbaf !important;
}
.configurator .lightblue {
  background-color: lightblue !important;
}
.configurator .darkgreen {
  background-color: darkgreen !important;
}
.configurator .cream {
  background-color: #c6bbaf !important;
}
.configurator .darkblue {
  background-color: #1e2e3c !important;
}
.configurator .blue {
  background-color: #516d86 !important;
}
.configurator .pink {
  background-color: rgb(248, 101, 126) !important;
}
.configurator .lilapastel {
  background-color: #BCAFCF !important;
}
.configurator .blue-gray {
  background: linear-gradient(90deg, rgb(158, 158, 158) 0%, rgb(158, 158, 158) 50%, rgb(81, 109, 134) 50%, rgb(81, 109, 134) 100%);
}
.configurator .pink-gray {
  background: linear-gradient(90deg, rgb(158, 158, 158) 0%, rgb(158, 158, 158) 50%, rgb(248, 101, 126) 50%, rgb(248, 101, 126) 100%);
}
.configurator .pink-cream {
  background: linear-gradient(90deg, rgb(198, 187, 175) 0%, rgb(198, 187, 175) 50%, rgb(248, 101, 126) 50%, rgb(248, 101, 126) 100%);
}
.configurator .blue-cream {
  background: linear-gradient(90deg, rgb(198, 187, 175) 0%, rgb(198, 187, 175) 50%, rgb(81, 109, 134) 50%, rgb(81, 109, 134) 100%);
}
.configurator .selected {
  background-color: white !important;
  border: 2px solid black !important;
  border-radius: 50%;
}
.configurator .button {
  background-color: whitesmoke;
  border: 1px solid lightgray;
  color: black;
}
.configurator .button:hover {
  background-color: #FF006B;
  color: white;
}
.configurator .button--selected {
  background-color: #FF006B !important;
  color: white !important;
}

.configurator__price {
  color: white !important;
  font-family: "Luckiest Guy" !important;
  font-size: 24px !important;
  letter-spacing: 2px;
  -webkit-user-select: none;
          -ms-user-select: none;
      user-select: none;
  cursor: default !important;
}

.loader__parent {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-animation: show 1s forwards ease-in-out;
          animation: show 1s forwards ease-in-out;
}
.loader__parent .logo {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  -webkit-animation: rotate 3s forwards linear;
          animation: rotate 3s forwards linear;
}

@-webkit-keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes rotate {
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate {
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.landing__gallery {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 0;
}
@media screen and (max-width: 720px) {
  .landing__gallery {
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
  }
}
.landing__gallery .story {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  transition: 0.5s ease-in-out;
}
.landing__gallery .story::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 25px 50px 30px;
  background: linear-gradient(180deg, rgba(155, 155, 155, 0.41) 0%, rgba(155, 155, 155, 0) 100%);
  border-radius: 20px;
  transition: 0.5s ease-in-out;
  z-index: 999999;
}
@media screen and (max-width: 720px) {
  .landing__gallery .story::after {
    background-color: rgba(150, 150, 150, 0);
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 720px) {
  .landing__gallery .story {
    width: 80%;
    height: 350px;
    margin: auto;
  }
}
.landing__gallery .story:hover {
  -webkit-transform: translate(0, -5%);
          transform: translate(0, -5%);
}
.landing__gallery .story:hover .story__content {
  -webkit-transform: rotate(5deg);
          transform: rotate(5deg);
}
.landing__gallery .story__image {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 20px;
  z-index: 9999;
}
@media screen and (max-width: 720px) {
  .landing__gallery .story__image {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
.landing__gallery .story__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px 50px 30px;
  background-color: #000;
  border-radius: 20px;
  z-index: -1;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 720px) {
  .landing__gallery .story__content {
    width: 90%;
    height: 90%;
    margin: 0 auto;
  }
}

.gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 0;
  -webkit-user-select: none;
          -ms-user-select: none;
      user-select: none;
}
@media screen and (max-width: 720px) {
  .gallery {
    flex-direction: column;
    grid-gap: 50px !important;
    gap: 50px !important;
  }
}
.gallery .story {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;
  width: 450px;
  height: 450px;
  margin: 10px;
  -webkit-transform: scale(0.85);
          transform: scale(0.85);
}
.gallery .story:hover .story__image {
  z-index: -1;
  -webkit-animation: init_story_image 1.5s ease-in-out forwards;
          animation: init_story_image 1.5s ease-in-out forwards;
}
.gallery .story:hover .story__content {
  z-index: 999999;
  -webkit-animation: init_story_content 1.5s ease-in-out forwards;
          animation: init_story_content 1.5s ease-in-out forwards;
}
@media screen and (max-width: 720px) {
  .gallery .story {
    width: 90vw;
    height: 90vw;
    margin: 0 auto;
  }
}
.gallery .story__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  z-index: 9999;
  border: 1px solid #9e9e9e;
  -webkit-animation: forwards show_story 0.5s ease-in-out;
          animation: forwards show_story 0.5s ease-in-out;
}
@media screen and (max-width: 720px) {
  .gallery .story__image {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
.gallery .story__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px 50px 30px;
  background-color: #000;
  border-radius: 20px;
  -webkit-transform: rotate(5deg);
          transform: rotate(5deg);
  z-index: 0;
  -webkit-animation: forwards show_story 0.5s ease-in-out;
          animation: forwards show_story 0.5s ease-in-out;
  overflow-y: auto;
}
@media screen and (max-width: 720px) {
  .gallery .story__content {
    width: 90%;
    height: 90%;
    margin: 0 auto;
  }
}
.gallery .story .title {
  color: white !important;
  text-transform: uppercase;
  font-size: 32px;
  text-align: center;
  z-index: -999 !important;
}
@media screen and (max-width: 720px) {
  .gallery .story .title {
    font-size: 24px;
  }
}

@-webkit-keyframes init_story_content {
  0% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
    z-index: -1;
  }
  49% {
    z-index: -1;
  }
  50% {
    -webkit-transform: translate(0%, -70%) scale(0.25) rotate(-100deg);
            transform: translate(0%, -70%) scale(0.25) rotate(-100deg);
    z-index: 999999;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1;
  }
}

@keyframes init_story_content {
  0% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
    z-index: -1;
  }
  49% {
    z-index: -1;
  }
  50% {
    -webkit-transform: translate(0%, -70%) scale(0.25) rotate(-100deg);
            transform: translate(0%, -70%) scale(0.25) rotate(-100deg);
    z-index: 999999;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 1;
  }
}
@-webkit-keyframes init_story_image {
  0% {
    z-index: 999;
  }
  100% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
    z-index: -1;
  }
}
@keyframes init_story_image {
  0% {
    z-index: 999;
  }
  100% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
    z-index: -1;
  }
}
@-webkit-keyframes show_story {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes show_story {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.page-header {
  padding: 50px 100px;
}
@media screen and (max-width: 720px) {
  .page-header {
    padding: 30px 0;
    width: 100%;
  }
}
.page-header .title {
  color: #FF006B !important;
}
@media screen and (max-width: 720px) {
  .page-header .title {
    font-size: 24px !important;
    text-align: center !important;
  }
}
@media screen and (max-width: 720px) {
  .page-header .subtitle {
    font-size: 20px !important;
    text-align: center !important;
  }
}

.landing__categories {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 0;
}
@media screen and (max-width: 720px) {
  .landing__categories {
    flex-direction: column;
  }
}
.landing__categories .category {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  transition: 0.5s ease-in-out;
  border-radius: 20px;
  box-shadow: 0px 0px 20px 0px rgba(255, 170, 170, 0.52);
}
@media screen and (max-width: 720px) {
  .landing__categories .category {
    width: 80%;
    height: 350px;
    margin: auto;
  }
}
.landing__categories .category .background {
  width: 100%;
  height: 500px;
  object-fit: cover;
  mix-blend-mode: multiply;
  border-radius: 20px;
}
@media screen and (max-width: 720px) {
  .landing__categories .category .background {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
.landing__categories .category:hover {
  -webkit-transform: translate(0, -5%);
          transform: translate(0, -5%);
}
.landing__categories .category .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 25px 50px 30px;
  background: linear-gradient(180deg, rgba(155, 155, 155, 0.41) 0%, rgba(155, 155, 155, 0) 100%);
  border-radius: 20px;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 720px) {
  .landing__categories .category .content {
    background-color: rgba(150, 150, 150, 0);
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
.landing__categories .category .title {
  color: white !important;
  text-transform: uppercase;
  font-size: 16px !important;
  text-align: center;
  background-color: #FF006B;
  padding: 5px;
  border-radius: 50px;
  width: 50%;
  margin: 0 auto;
}
@media screen and (max-width: 720px) {
  .landing__categories .category .title {
    font-size: 14px !important;
    width: 75%;
  }
}
.landing__categories .category .button {
  width: -moz-fit-content !important;
  width: -webkit-fit-content !important;
  width: fit-content !important;
  font-weight: bold !important;
  border: none;
  text-align: center;
  background: white;
  color: #222;
  cursor: pointer;
  outline: none;
  padding: 15px 30px;
  -webkit-user-select: none;
          -ms-user-select: none;
      user-select: none;
  border-radius: 0px;
}
@media screen and (max-width: 720px) {
  .landing__categories .category .button {
    margin: 0 auto;
  }
}

.landing__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 50px;
  gap: 50px;
  background: linear-gradient(93deg, #FBF6F6 0.11%, #F5E6E6 100%);
}
.landing__container--second {
  background: linear-gradient(93deg, #FBF6F6 0.11%, #66C3D1 100%);
}
.landing__container .image {
  width: 400px;
  height: 400px;
  object-fit: contain;
  -webkit-transform: rotate(2.985deg) translate(0, 7.5%);
          transform: rotate(2.985deg) translate(0, 7.5%);
  border-radius: 20px;
  box-shadow: 0px 0px 50px 0px rgba(255, 220, 220, 0.5);
}
@media screen and (max-width: 720px) {
  .landing__container .image {
    margin: auto;
    width: 75%;
    height: 75%;
  }
}
.landing__container .image--logo {
  width: 400px;
  height: 400px;
  object-fit: contain;
  -webkit-transform: translate(0, 7.5%);
          transform: translate(0, 7.5%);
}
@media screen and (max-width: 720px) {
  .landing__container .image--logo {
    margin: auto;
    width: 75%;
    height: 75%;
  }
}
.landing__container .content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
}
@media screen and (max-width: 720px) {
  .landing__container .content {
    padding: 20px;
    width: 100%;
  }
}
.landing__container .content .title {
  color: black !important;
  text-transform: uppercase;
  font-size: 26px;
  text-align: start;
}
@media screen and (max-width: 720px) {
  .landing__container .content .title {
    padding: 20px 0;
    text-align: center;
  }
}
.landing__container .content .title .input__group {
  padding: 0;
}
.landing__container .content .description {
  color: 222 !important;
  font-family: "Poppins", sans-serif;
  text-align: start;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
}
@media screen and (max-width: 720px) {
  .landing__container .content .description {
    text-align: center;
  }
}
.landing__container .content .description .input__group {
  padding: 0;
}
.landing__container .content .button {
  width: -moz-fit-content !important;
  width: -webkit-fit-content !important;
  width: fit-content !important;
  font-family: "Poppins", sans-serif;
  border: none;
  text-align: center;
  background: white;
  color: #222;
  cursor: pointer;
  outline: none;
  padding: 10px 40px !important;
  -webkit-user-select: none;
          -ms-user-select: none;
      user-select: none;
  border-radius: 40px;
}
@media screen and (max-width: 720px) {
  .landing__container .content .button {
    margin: 0 auto;
  }
}

.landing__slider {
  position: relative;
  width: 95%;
  height: 60vh !important;
  border-radius: 50px;
  margin: 50px auto;
  z-index: 1;
  border-radius: 50px;
  box-shadow: 0px 0px 20px 0px rgba(255, 170, 170, 0.52);
}
@media screen and (max-width: 1024px) {
  .landing__slider {
    width: 95%;
  }
}
.landing__slider .slider__arrows {
  position: absolute;
  bottom: 7.5%;
  right: 5%;
  z-index: 9999999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
@media screen and (max-width: 720px) {
  .landing__slider .slider__arrows {
    right: 10%;
  }
}
.landing__slider .slider__arrows .arrow-btn {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  line-height: 14px;
  font-size: 14px;
  padding: 7.5px;
  cursor: pointer;
}
.landing__slider .slide {
  position: relative;
}
.landing__slider .background {
  width: 100%;
  height: 60vh !important;
  border-radius: 50px;
  object-fit: cover;
}
@media screen and (max-width: 1024px) {
  .landing__slider .background {
    object-fit: contain;
  }
}
.landing__slider .overlay {
  position: absolute;
  bottom: 15px;
  left: 50%;
  width: 200px;
  min-height: 50px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  z-index: 9999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.fitswell {
  width: 90% !important;
}

.products {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
}
.products--landing {
  justify-content: space-between;
}
@media screen and (max-width: 720px) {
  .products {
    justify-content: center;
    grid-gap: 25px;
    gap: 25px;
    margin-top: 20px;
  }
}
.products .product {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  -webkit-user-select: none;
          -ms-user-select: none;
      user-select: none;
  width: auto !important;
  width: initial !important;
  max-width: 400px !important;
  overflow: hidden;
  flex-grow: 1;
  padding: 2px;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 720px) {
  .products .product {
    width: 80% !important;
    flex-grow: 0 !important;
  }
}
.products .product:hover {
  -webkit-transform: translate(0, -5%);
          transform: translate(0, -5%);
}
.products .product .image {
  position: relative;
  width: 100%;
  height: 450px;
  overflow: hidden;
}
@media screen and (max-width: 720px) {
  .products .product .image {
    width: 100% !important;
    height: 300px;
  }
}
.products .product .image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  border: 2px solid #F6E7E7;
  object-fit: contain;
  mix-blend-mode: multiply;
}
.products .product .title {
  width: 100%;
  color: black;
  font-weight: bold !important;
  text-align: start;
}
@media screen and (max-width: 720px) {
  .products .product .title {
    font-size: 12px !important;
  }
}
.products .product .description {
  width: 100%;
  color: black;
  font-size: 12px !important;
  font-weight: lighter !important;
  font-family: "Poppins", sans-serif !important;
  text-align: start;
}
@media screen and (max-width: 720px) {
  .products .product .description {
    font-size: 10px !important;
  }
}
.products .product .price {
  width: 100%;
  text-align: start;
  color: #000;
  letter-spacing: 2px;
}
@media screen and (max-width: 720px) {
  .products .product .price {
    font-size: 12px !important;
  }
}
.products .product .text-promo {
  width: 100%;
  text-align: start;
  color: #FF0000;
  font-weight: bold !important;
}
@media screen and (max-width: 720px) {
  .products .product .text-promo {
    font-size: 10px !important;
  }
}

.pheader {
  position: relative;
  display: flex;
  margin: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
@media screen and (max-width: 720px) {
  .pheader {
    flex-direction: column;
  }
}

.product__container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 720px) {
  .product__container {
    align-items: center;
    flex-direction: column;
  }
}
.product__container .bg-sec {
  background-color: #FF006B;
}
.product__container .images {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media screen and (max-width: 720px) {
  .product__container .images {
    margin: 0 auto;
    flex-direction: column;
  }
}
.product__container .images .preview {
  position: relative;
  width: 45vw;
  height: auto;
}
@media screen and (max-width: 720px) {
  .product__container .images .preview {
    width: 90vw !important;
    height: 60vh !important;
  }
}
.product__container .images .preview .slide {
  position: relative;
  width: 35vw;
  height: auto;
  object-fit: contain !important;
  mix-blend-mode: multiply;
}
@media screen and (max-width: 720px) {
  .product__container .images .preview .slide {
    width: 90vw !important;
    height: 60vh !important;
  }
}
.product__container .images .image {
  position: relative;
  width: 100px;
  height: 100px;
  object-fit: cover !important;
}
.product__container .title {
  color: black !important;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
}
.product__container .price {
  color: #FF006B !important;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 34px;
}
.product__container .label {
  font-weight: bold;
  font-size: 16px;
  color: #B9BBBF;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}
.product__container .button {
  background-color: #000;
  width: 100%;
  border-radius: 35px;
}
.product__container .button--buy {
  background-color: #FF006B;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  height: 65px;
  width: 50%;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.product__container .button--buy:hover {
  background-color: #FF006B !important;
}
@media screen and (max-width: 720px) {
  .product__container .button {
    margin: 0 0 !important;
    width: 100% !important;
    padding: 8px;
  }
}
.product__container .button:hover {
  background-color: #333;
}
.product__container .button--reverse {
  width: 50%;
  height: 65px;
  margin-left: 50px;
  background-color: #fff;
  color: #FF006B;
  border: 1px solid #FF006B;
  border-radius: 35px;
  padding: 8px;
  transition: 0.5s ease-in-out;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.product__container .button--reverse img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  -webkit-filter: invert(17%) sepia(91%) saturate(6208%) hue-rotate(329deg) brightness(104%) contrast(109%);
          filter: invert(17%) sepia(91%) saturate(6208%) hue-rotate(329deg) brightness(104%) contrast(109%);
}
.product__container .button--reverse:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  border: 1px solid 333;
}
@media screen and (max-width: 720px) {
  .product__container .button--reverse:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
  }
}
@media screen and (max-width: 720px) {
  .product__container .button--reverse {
    width: 100%;
    margin-left: 0px;
  }
}
.product__container .button--rev-active {
  width: 50%;
  height: 65px;
  margin-left: 50px;
  background-color: #FF006B;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 35px;
  padding: 8px;
  transition: 0.5s ease-in-out;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.product__container .button--rev-active img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  -webkit-filter: invert(1);
          filter: invert(1);
}
.product__container .button--rev-active:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
@media screen and (max-width: 720px) {
  .product__container .button--rev-active:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
  }
}
.product__container .button--rev-active:hover img {
  -webkit-filter: invert(1);
          filter: invert(1);
}
@media screen and (max-width: 720px) {
  .product__container .button--rev-active {
    width: 100%;
    margin-left: 0px;
  }
}
.product__container .quantity {
  height: 65px;
  border-radius: 30px;
  padding: 4px 10px;
  font-size: 20px;
  color: #FF006B;
  background-color: #F3F3F3;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  -webkit-user-select: none;
          -ms-user-select: none;
      user-select: none;
}
@media screen and (max-width: 720px) {
  .product__container .quantity {
    padding: 4px 10px;
    font-size: 12px;
  }
}
.product__container .quantity .button {
  border: none;
  background-color: transparent;
  font-size: 32px;
  line-height: 20px;
  cursor: pointer;
  color: #FF006B;
  padding: 0;
  outline: none;
}
@media screen and (max-width: 720px) {
  .product__container .quantity .button {
    font-size: 12px;
    line-height: 10px;
    padding: 0 !important;
  }
}
.product__container .sizes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  -webkit-user-select: none;
          -ms-user-select: none;
      user-select: none;
}
.product__container .sizes .active {
  background-color: #333 !important;
  color: white;
}
.product__container .sizes .size {
  width: 64px !important;
  height: 64px !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #999;
  border-radius: 50%;
  transition: 0.5s ease-in-out;
  margin: 4px;
}
.product__container .sizes .size:hover {
  border: 1px solid #000;
}
.product__container .sizes .size .value {
  width: 40px !important;
  height: 40px !important;
  align-items: center;
  text-align: center;
  line-height: 42px;
  border-radius: 50%;
  margin: auto;
}
.product__container .colors {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}
.product__container .colors .active {
  border: 2px solid black;
  border-radius: 50%;
}
.product__container .colors .active .color {
  margin: 2px;
}
.product__container .colors .color {
  width: 64px !important;
  height: 64px !important;
  background-color: white;
  border: 1px solid #999;
  border-radius: 50%;
  margin: 4px;
}
.product__container .colors .red {
  background-color: rgb(221, 42, 42);
}
.product__container .colors .blue {
  background-color: rgb(59, 160, 255);
}
.product__container .colors .green {
  background-color: rgb(59, 226, 59);
}
.product__container .colors .yellow {
  background-color: rgb(255, 237, 78);
}
.product__container .colors .orange {
  background-color: rgb(255, 166, 0);
}
.product__container .colors .purple {
  background-color: rgb(153, 32, 153);
}
.product__container .colors .black {
  background-color: black;
}
.product__container .colors .white {
  background-color: white;
}
.product__container .colors .grey {
  background-color: grey;
}
.product__container .colors .brown {
  background-color: rgb(102, 37, 17);
}
.product__container .colors .pink {
  background-color: rgb(255, 145, 163);
}
.product__container .colors .nude {
  background-color: #E3BC9A;
}

@-webkit-keyframes button__buy {
  0% {
    -webkit-transform: translate(-2px, 1px);
            transform: translate(-2px, 1px);
  }
  25% {
    -webkit-transform: translate(-2px, 0px);
            transform: translate(-2px, 0px);
  }
  50% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  75% {
    -webkit-transform: translate(0px, 1px);
            transform: translate(0px, 1px);
  }
  100% {
    -webkit-transform: translate(2px, 1px);
            transform: translate(2px, 1px);
  }
}

@keyframes button__buy {
  0% {
    -webkit-transform: translate(-2px, 1px);
            transform: translate(-2px, 1px);
  }
  25% {
    -webkit-transform: translate(-2px, 0px);
            transform: translate(-2px, 0px);
  }
  50% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  75% {
    -webkit-transform: translate(0px, 1px);
            transform: translate(0px, 1px);
  }
  100% {
    -webkit-transform: translate(2px, 1px);
            transform: translate(2px, 1px);
  }
}
.dropdown_card {
  background-color: #FAFAFA;
  color: #000;
}

footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px 50px 0;
  border-radius: 50px 50px 0px 0px;
  box-shadow: 0px 0px 10px 0px rgba(56, 56, 56, 0.5);
}
@media only screen and (max-width: 720px) {
  footer {
    padding: 50px 30px 0;
  }
}
footer .logo {
  height: 150px;
  width: auto;
  object-fit: cover;
}
@media only screen and (max-width: 720px) {
  footer .logo {
    width: 100px;
    height: auto;
  }
}
footer .choice {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ddd;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
footer .choice img {
  width: 50%;
  height: 50%;
  margin: auto;
  object-fit: cover;
}
footer hr {
  width: 75%;
  border: none;
  margin: 25px auto;
}
@media only screen and (max-width: 720px) {
  footer hr {
    width: 100%;
  }
}
footer .color--black {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
@media only screen and (max-width: 720px) {
  footer .color--black {
    font-size: 16px;
  }
}
footer .color--gray {
  text-decoration: none !important;
  color: #646464;
  cursor: pointer;
}
@media only screen and (max-width: 720px) {
  footer .color--gray {
    font-size: 12px;
  }
}
footer .copyright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  padding: 10px 100px;
  color: grey;
}
@media only screen and (max-width: 720px) {
  footer .copyright {
    flex-direction: column-reverse;
    grid-gap: 30px;
    gap: 30px;
    padding: 10px;
    font-size: 10px;
  }
}

.filters {
  width: 15%;
  height: -webkit-max-content;
  height: max-content;
  transition: 0.5s ease-in-out;
  font-family: "Poppins", sans-serif !important;
  margin-right: 20px;
  color: black;
}
@media screen and (max-width: 720px) {
  .filters {
    width: 100%;
    margin-right: 0;
  }
}
.filters__content {
  width: auto;
  min-width: 300px;
  height: 100%;
  background-color: white;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  font-weight: lighter;
  -webkit-animation: init__filters__menu 0.5s ease-in-out forwards;
          animation: init__filters__menu 0.5s ease-in-out forwards;
}
@media screen and (max-width: 720px) {
  .filters__content {
    width: 100%;
  }
}
.filters__title {
  position: relative;
  font-size: 24px;
  font-weight: 500;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  font-family: "Luckiest Guy", sans-serif !important;
}
.filters__icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
  margin-right: 5px;
  -webkit-transform: scale(0.8) translate(0, -10%);
          transform: scale(0.8) translate(0, -10%);
}
.filters__choice__content {
  -webkit-animation: filters__choice__content 0.5s ease-in-out forwards;
          animation: filters__choice__content 0.5s ease-in-out forwards;
}
.filters__choice__content--opened {
  -webkit-animation: filters__choice__content 0.5s ease-in-out forwards;
          animation: filters__choice__content 0.5s ease-in-out forwards;
}
.filters__choice__content--closed {
  -webkit-animation: filters__choice__hide 0.5s ease-in-out forwards;
          animation: filters__choice__hide 0.5s ease-in-out forwards;
}
.filters__choice__icon--open {
  -webkit-animation: filters__choice__icon__open 0.5s ease-in-out forwards;
          animation: filters__choice__icon__open 0.5s ease-in-out forwards;
}
.filters__choice__icon--close {
  -webkit-animation: filters__choice__icon__close 0.5s ease-in-out forwards;
          animation: filters__choice__icon__close 0.5s ease-in-out forwards;
}
.filters__close {
  cursor: pointer;
  -webkit-user-select: none;
          -ms-user-select: none;
      user-select: none;
  transition: 0.5s ease-in-out;
  -webkit-transform: translate(0, -10%);
          transform: translate(0, -10%);
}
.filters__close:hover {
  -webkit-transform: translate(0, -10%) rotate(360deg);
          transform: translate(0, -10%) rotate(360deg);
}

@-webkit-keyframes init__filters__menu {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: auto;
    opacity: 1;
  }
}

@keyframes init__filters__menu {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: auto;
    opacity: 1;
  }
}
@-webkit-keyframes filters__choice__title__open {
  from {
    width: 0;
  }
  to {
    width: 75%;
  }
}
@keyframes filters__choice__title__open {
  from {
    width: 0;
  }
  to {
    width: 75%;
  }
}
@-webkit-keyframes filters__choice__title__close {
  from {
    width: 75%;
  }
  to {
    width: 0;
  }
}
@keyframes filters__choice__title__close {
  from {
    width: 75%;
  }
  to {
    width: 0;
  }
}
@-webkit-keyframes filters__choice__content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes filters__choice__content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes filters__choice__hide {
  from {
    opacity: 1;
    height: auto;
  }
  to {
    opacity: 0;
    height: 0;
  }
}
@keyframes filters__choice__hide {
  from {
    opacity: 1;
    height: auto;
  }
  to {
    opacity: 0;
    height: 0;
  }
}
@-webkit-keyframes filters__choice__icon__open {
  from {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes filters__choice__icon__open {
  from {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@-webkit-keyframes filters__choice__icon__close {
  from {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes filters__choice__icon__close {
  from {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
.wapp--button {
  position: fixed;
  width: 90px;
  height: 90px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  font-size: 60px;
  line-height: 90px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  transition: 0.5s ease-in-out;
  -webkit-animation: fadeIn 0.5s ease-in-out forwards;
          animation: fadeIn 0.5s ease-in-out forwards;
}
.wapp--button:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  color: #FFF;
}
@media only screen and (max-width: 720px) {
  .wapp--button {
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    font-size: 40px;
    line-height: 60px;
  }
}

.reviews__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  list-style: none;
  grid-gap: 30px;
  gap: 30px;
}
.reviews__list .slick-arrow {
  padding: auto;
  margin: auto;
  z-index: 1;
  background-color: #000 !important;
  color: #000 !important;
  width: 50px;
  height: 50px;
}
.reviews__list .slick-prev {
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 50%;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}
@media screen and (max-width: 720px) {
  .reviews__list .slick-prev {
    top: 95%;
    -webkit-transform: translate(-35%, 0);
            transform: translate(-35%, 0);
  }
}
.reviews__list .slick-prev:before {
  font-family: "Quicksand", sans-serif !important;
  content: "←";
  border-radius: 100%;
}
.reviews__list .slick-next {
  position: absolute;
  top: 50%;
  right: 0;
  border-radius: 50%;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}
@media screen and (max-width: 720px) {
  .reviews__list .slick-next {
    top: 95%;
    -webkit-transform: translate(35%, 0);
            transform: translate(35%, 0);
  }
}
.reviews__list .slick-next:before {
  font-family: "Poppins", sans-serif !important;
  content: "→";
  border-radius: 100%;
}
.reviews__list .review {
  position: relative;
  background-color: #eaeaea;
  padding: 20px 30px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  grid-gap: 40px;
  gap: 40px;
  width: auto;
  margin: 0 10px;
}
@media screen and (max-width: 720px) {
  .reviews__list .review {
    padding: 10px 15px;
  }
}
.reviews__list .review__person {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
}
.reviews__list .review__person .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.reviews__list .review__content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;
  text-wrap: wrap;
  height: 80px;
  overflow-y: auto;
}
.reviews__list .review__content--edit {
  height: auto;
}

@font-face {
  font-family: "Droids Sans";
  src: url("/static/media/Droids Sans Italic.be3a8e21.otf") format("opentype");
}
.disclaimer {
  font-family: "Droids Sans", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .disclaimer {
    padding: 20px;
    flex-direction: column;
  }
}
.disclaimer img {
  width: 300px;
  height: 150px;
  padding: 0 25px;
  margin: 20px 0;
  object-fit: contain;
  mix-blend-mode: multiply;
  border-radius: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.disclaimer a {
  text-decoration: underline;
  color: #333;
}

* {
  margin: 0;
  padding: 0;
  -webkit-user-drag: none;
  box-sizing: border-box;
}

body {
  font-family: "Architects Daughter", sans-serif;
  background-color: white;
  font-weight: 800;
}

.layout {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 75px;
  gap: 75px;
  padding: 75px 0;
}
@media only screen and (max-width: 720px) {
  .layout {
    padding-bottom: 20px;
  }
}

.image--loading {
  background: #f9f9f9;
  border-radius: 5px;
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}

.lazyload {
  -webkit-animation: show 0.5s forwards ease-in-out;
          animation: show 0.5s forwards ease-in-out;
}

a {
  color: inherit;
  text-decoration: inherit;
}
a:hover {
  color: inherit;
  text-decoration: inherit;
}

.text--poppins {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 20px !important;
  line-height: 30px;
  font-weight: lighter;
}

p {
  font-size: 14px !important;
  line-height: 18px;
  font-weight: lighter;
}

.input__group {
  width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 25px;
}
.input__group label {
  width: 100%;
  font-size: 1rem;
  font-weight: lighter;
  color: #5E5E5E;
}
.input__group input {
  width: 100%;
  padding: 5px 15px;
  border: none;
  border: 1px solid #9e9e9e;
  border-radius: 20px;
  background-color: transparent !important;
  font-size: 1.2rem;
  color: #5E5E5E;
  outline: none;
}
.input__group .button {
  width: 100%;
  border: none;
  text-align: center;
  background: #000;
  color: white;
  font-size: 1rem;
  font-weight: lighter;
  cursor: pointer;
  outline: none;
  padding: 12px 25px !important;
  border-radius: 30px;
}
@media only screen and (max-width: 720px) {
  .input__group {
    width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    padding: 25px;
  }
}

.password__group {
  position: relative;
}
.password__group .visibility {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: auto;
  border: none;
  background: transparent;
  color: #5E5E5E;
}

.slide_image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.bold {
  font-weight: 700;
}

.bolder {
  font-weight: 900;
}

.button {
  border: none;
  text-align: center;
  background: #000;
  color: white;
  font-size: 1rem;
  font-weight: lighter;
  cursor: pointer;
  outline: none;
  padding: 12px 25px !important;
  border-radius: 30px;
  transition: 0.5s ease-in-out;
}
.button--buy {
  background-color: #FF006B;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin: 0 auto;
  padding: 12px 40px !important;
  height: 65px;
  width: auto !important;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.button--buy:hover {
  background-color: #FF006B !important;
}
.button--bg {
  padding: 7px 15px;
  text-align: center;
  color: white;
  background: invisible !important;
  border: 1px solid white !important;
  border-radius: 2px;
  transition: 1s ease-in-out;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.button--bg:hover {
  background: #000;
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}
.button--bg:active {
  background: #000;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.button:hover {
  background: #000;
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}
.button:active {
  background: #000;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
@media screen and (max-width: 720px) {
  .button {
    font-size: 0.75rem;
  }
}

.icon {
  -webkit-transform: translateY(25%) scale(0.75);
          transform: translateY(25%) scale(0.75);
}

.w-fit {
  width: -moz-fit-content !important;
  width: -webkit-fit-content !important;
  width: fit-content !important;
}

.h-fit {
  height: -moz-fit-content !important;
  height: -webkit-fit-content !important;
  height: fit-content !important;
}

hr {
  width: 75%;
  border: none;
  margin: 25px auto;
}
@media only screen and (max-width: 720px) {
  hr {
    width: 100%;
  }
}

.whitespace-break-spaces {
  white-space: pre-line !important;
}

.pointer {
  cursor: pointer;
}

.landing__title {
  position: relative;
  text-transform: uppercase;
  font-size: 32px;
  padding: 20px;
}
@media screen and (max-width: 720px) {
  .landing__title {
    padding-bottom: 0px;
  }
}

.payments__icon {
  width: 200px;
  height: 25px;
  object-fit: contain;
}

.newsletter__input {
  position: relative;
  width: 100%;
}
@media screen and (max-width: 720px) {
  .newsletter__input {
    width: 90%;
    margin: auto;
  }
}
.newsletter__input input {
  width: 100%;
  padding: 12px 25px;
  border-radius: 30px;
  font-size: 1rem;
  border: 1px solid black;
}
@media screen and (max-width: 720px) {
  .newsletter__input input {
    width: 100%;
    margin: auto;
    font-size: 12px;
    padding: 8px 14px;
  }
}
.newsletter__input__button {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: auto;
  border: none;
  background: #000;
  color: white;
  font-size: 1rem;
  font-weight: lighter;
  cursor: pointer;
  outline: none;
  padding: 10px 25px;
  margin-right: 3px;
  transition: 0.5s ease-in-out;
  border-radius: 30px;
}
@media screen and (max-width: 720px) {
  .newsletter__input__button {
    font-size: 12px;
    padding: 6px 14px;
  }
}

.bg-sec {
  background-color: #FF006B;
}

.round_corner {
  border-radius: 35px;
}

.details {
  font-weight: normal !important;
  font-weight: initial !important;
}

.sizeguide {
  max-width: 450px;
  max-height: auto;
}
@media screen and (max-width: 720px) {
  .sizeguide {
    max-width: 90vw;
    max-height: auto;
  }
}/*# sourceMappingURL=index.css.map */
